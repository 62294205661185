var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-back"},[_c('div',{staticClass:"container section p-3"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 pb-0"},[_c('auto-complete',{attrs:{"placeholder":_vm.$store.state.address_text.full_address,"size":"is-normal"},on:{"selectedItem":function($event){return _vm.selectAddress($event)}}})],1),(_vm.getFeatures.level >= '1')?_c('div',{staticClass:"column is-12"},[_c('b-button',{staticClass:"is-primary is-outlined is-fullwidth",attrs:{"tag":"router-link","to":{
                            name: 'report',
                            params: { lang: _vm.$route.params.lang },
                            query: { ..._vm.$route.query, static: 'true' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('report'))+" ")])],1):_c('div',{staticClass:"column is-12 pb-0 pt-5"},[_c('b-notification',{attrs:{"type":"is-warning","position":"center","closable":false}},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('low_level_warning_title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('low_level_warning_body')))])]),_c('b-notification',{staticClass:"content has-text-black",attrs:{"position":"center","closable":false}},[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('low_level_details_title'))+" ")]),_c('ul',{staticClass:"ml-3"},[_c('li',[_vm._v(_vm._s(_vm.$t('low_level_details_step1')))]),_c('li',[_vm._v(_vm._s(_vm.$t('low_level_details_step2')))])])])],1),(_vm.closable)?_c('div',{staticClass:"column is-12",staticStyle:{"margin-top":"-0.75rem"}},[_c('b-button',{staticClass:"is-primary is-fullwidth",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1):_vm._e()])])]),_c('div',{key:'general',staticClass:"category-info location mb-1"},[_c('div',{staticClass:"info-content"},[_vm._m(0),_c('div',{staticClass:"info-name"},[_vm._v(_vm._s(_vm.getAddress.full_address))])])]),_vm._l((_vm.sections.filter(
            (section) => section.items.length || _vm.$route.query.showmissing
        )),function(section){return _c('b-collapse',{key:section.name,staticClass:"card",attrs:{"animation":"slide","open":_vm.openSection == section.name},on:{"open":function($event){_vm.openSection = section.name}},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"sidebar_item",attrs:{"role":"button"}},[_c('span',[(section.name !== 'warnings' && section.icon)?_c('img',{staticClass:"icon is-medium",attrs:{"src":`${_vm.publicPath}/${section.icon}.svg`}}):_vm._e(),(section.name === 'warnings')?_c('span',{staticClass:"icon is-medium my-2 mx-3 has-text-warning"},[_c('i',{staticClass:"mdi mdi-alert mdi-24px"})]):_vm._e(),_c('span',{staticClass:"noselect has-text-weight-bold is-size-6"},[_vm._v(" "+_vm._s(_vm.$t('section.' + section.name))+" ")])]),_c('span',[_c('span',{staticClass:"tag is-rounded"},[_vm._v(" "+_vm._s(section.items.length)+" ")]),_c('i',{staticClass:"arrow",class:props.open ? 'up' : 'down'})])])]}}],null,true)},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},_vm._l((section.items),function(item){return _c('section-item',{key:item.name,attrs:{"item":item,"orientation":'horizontal'}})}),1)])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/icons/ic-location-blue.svg"),"alt":"location icon"}})])
}]

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/shared/plugins/axios'
import utils from '@/shared/plugins/utils'

import log from '../../../Monolith/src/store/Log/index'

Vue.use(Vuex)

const defaultRoles = () => ({
    '*': [],
    cat: [],
    avm: [],
    dvm: [],
    ers: [],
    ovm: [],
})

const getDefaultState = () => {
    return {
        auth: {
            authenticated: null,
            email: null,
            username: null,
            roles: defaultRoles(),
        },
        address_text: {
            postalcode: null,
            streetname: null,
            streetnumber: null,
            municipality: null,
            boxnumber: null,
        },
        features: {
            building_id: null,
            level: null,
            c_economic_activity_class: null,
            c_economic_activity_level: null,
            errors: null,
            f_annexes_area: null,
            f_annexes_volume: null,
            distances_annexes: [],
            exterior_wall_area: null,
            f_area_largest_annex: null,
            f_best_pane_area: null,
            f_best_pane_azimuth: null,
            f_best_pane_azimuth_name: null,
            f_best_pane_tilt: null,
            f_building_area: null,
            f_building_type: null,
            f_building_type_proba: null,
            f_dist_building_flood: null,
            f_dist_building_flood_insurance_exclusion: null,
            f_dist_fire_station: null,
            f_dist_parcel_flood: null,
            f_dist_parcel_flood_insurance_exclusion: null,
            f_dist_xy_flood: null,
            f_dist_xy_flood_insurance_exclusion: null,
            f_distance_to_street: null,
            f_flat_proportion: null,
            f_flood_risk: null,
            f_flood_risk_composite: null,
            f_flood_risk_insurance_exclusion: null,
            f_flood_risk_max_parcel: null,
            f_flood_type: null,
            f_garden_area: null,
            f_ground_height_above_sea: null,
            f_inner_volume: null,
            f_lat: null,
            f_lng: null,
            f_matched_entities: null,
            f_max_height: null,
            f_max_roof_height: null,
            f_mean_roof_tilt: null,
            f_mean_tilt: null,
            f_min_height: null,
            f_min_roof_height: null,
            f_n_parts: null,
            f_n_roof_panes: null,
            f_neighbour_cover_area: null,
            f_neighbour_type: null,
            f_number_of_addresses: null,
            f_parcel_area: null,
            f_percent_of_roof_flat: null,
            f_roof_area: null,
            f_swimming_pool: null,
            f_swimming_pool_proba: null,
            f_swimming_pools_area: null,
            f_total_inner_volume_base: null,
            f_total_inner_volume_roof: null,
            f_touching_sides: null,
            f_volume: null,
            f_volume_largest_annex: null,
            f_x: null,
            f_x_annexes: null,
            f_x_matched_entities: null,
            f_x_swimming_pools: null,
            f_y: null,
            parcel_ids: [],
            touching_side_type: null,
            warnings: null,
        },
        map_scene2d: {
            building_geojson: null,
            parcel_geojson: null,
            address_geojson: null,
            swimming_pool_geojson: null,
            flood_geojson: null,
        },
        map_scene3d: {},
        valuation: {
            index_type: null,
            index_value: null,
            reconstruction_value: null,
        },
        view_options: {
            t_angle: null,
            t_pitch: null,
            t_dist: null,
            gsv_available: null, //GoogleStreetView available
            gsv_ph: null, // GoogleStreetView pov heading
            gsv_init_ph: null, // GoogleStreetView initial pov heading
            gsv_pp: null, // GoogleStreetView pov pitch
            gsv_pz: null, // GoogleStreetView pov zoom
            gsv_p: null, // GoogleStreetView pano id
            gsv_lat: null,
            gsv_lng: null,
            m_lat: null,
            m_lng: null,
            m_z: null,
        },
    }
}

const state = getDefaultState()

export default new Vuex.Store({
    state,
    getters: {
        getFullStore(state, getters) {
            return {
                auth: state.auth,
            }
        },
        getFullValuationStore(state, getters) {
            return {
                address_text: {
                    ...state.address_text,
                    full_address: utils.full_address(state.address_text, state.features),
                },
                features: getters.getFeatures,
                map_scene2d: state.map_scene2d,
                map_scene3d: state.map_scene3d,
                valuation: state.valuation,
            }
        },
        getValuationType(state) {
            return 'cat'
        },
        get_map_scene2d(state) {
            return state.map_scene_2d ? state.map_scene_2d : state.map_scene2d
        },
        getFeatures(state) {
            let f_number_of_facades = null
            if (state.features.f_touching_sides === 0) {
                f_number_of_facades = 'detached'
            } else if (state.features.f_touching_sides === 1) {
                f_number_of_facades = 'semi'
            } else if (state.features.f_touching_sides >= 2) {
                f_number_of_facades = 'attached'
            }
            let f_flood_risk_insurance_exclusion =
                state.features.f_flood_risk_insurance_exclusion
            if (Vue.prototype.$config.INSURANCE_EXCLUSION_BUFFER_1M) {
                if (state.features.level === '1') {
                    f_flood_risk_insurance_exclusion =
                        state.features.f_dist_xy_flood_insurance_exclusion <= 1
                            ? 'High'
                            : 'None'
                } else if (state.features.level >= '2') {
                    f_flood_risk_insurance_exclusion =
                        state.features.f_dist_building_flood_insurance_exclusion <= 1
                            ? 'High'
                            : 'None'
                }
            }
            let features = {
                ...state.features,
                parcel_ids: state.features.parcel_ids ?? [],
                f_number_of_facades,
                f_flood_risk_insurance_exclusion,
            }
            return features
        },
        getValuation(state) {
            return state.valuation
        },
        getDVMFeatures(state) {
            return {}
        },
        getOVMFeatures(state) {
            return {}
        },
        getView(state) {
            return state.view_options
        },
        addressInfo(state) {
            // TODO: Is this still used anywhere? Should be replaced by getAddress
            return {
                ...state.address_text,
                full_address: utils.full_address(state.address_text),
                short_address: utils.short_address(state.address_text),
            }
        },
        getAddress(state) {
            return {
                ...state.address_text,
                full_address: utils.full_address(state.address_text, state.features),
                via_address: utils.via_address(state.address_text, state.features),
                short_address: utils.short_address(state.address_text, state.features),
            }
        },
        hasRole(state) {
            return (role, module) =>
                state.auth.roles['*'].includes(role) ||
                state.auth.roles[module]?.includes(role)
        },
        getDefaultState,
    },
    mutations: {
        UPDATE_STATUS(state, data) {
            Object.assign(state, data)
        },
        UPDATE_ADDRESS(state, data) {
            state.address_text = data
        },
        UPDATE_FEATURES(state, data) {
            Object.assign(state.features, data)
        },
        UPDATE_MAP_SCENE2D(state, data) {
            state.map_scene2d = data
        },
        UPDATE_MAP_SCENE3D(state, data) {
            state.map_scene3d = data
        },
        SET_VALUATION(state, data) {
            state.valuation = data
        },
        RESET_VAL(state) {
            Object.assign(state, getDefaultState())
        },
        UPDATE_VIEW(state, data) {
            Object.assign(state.view_options, data)
        },
        SET_AUTH(state, data) {
            state.auth.email = data.email || null
            state.auth.roles = data.roles || defaultRoles()
            state.auth.username = data.username || null
            state.auth.authenticated = data.authenticated || null
        },
    },
    actions: {
        updateBuildingId(context, building_id) {
            console.log({ event: 'updateBuildingId', building_id })
            context.commit('UPDATE_FEATURES', { building_id: building_id })
            if (building_id === null) {
                context.commit('UPDATE_FEATURES', {
                    level: '0',
                    f_lat: 51,
                    f_lng: 4.2,
                    parcel_ids: [],
                })
                context.commit('UPDATE_VIEW', getDefaultState().view_options)
                return
            }
            return axios
                .get(`/features/${context.state.features.building_id}`, {
                    params: {
                        minimum_level: 0,
                        estimate_reconstruction_value: false,
                    },
                })
                .then((response) => {
                    context.commit('UPDATE_FEATURES', response.data)
                    context.dispatch('fetchReconstructionValue')
                    context.dispatch('fetchStreetviewInfo')
                })
        },
        fetchReconstructionValue(context) {
            if (context.state.features.level >= '1') {
                return axios
                    .get(
                        `/estimate/reconstruction/${context.state.features.building_id}`,
                        {
                            params: {
                                f_building_type: 'house',
                            },
                        }
                    )
                    .then((response) => {
                        context.commit('SET_VALUATION', response.data)
                    })
                    .catch((error) => {
                        context.commit('SET_VALUATION', getDefaultState().valuation)
                    })
            } else {
                context.commit('SET_VALUATION', getDefaultState().valuation)
            }
        },
        fetchStreetviewInfo(context) {
            return axios
                .get(`/meta/streetview`, {
                    params: {
                        lat: context.state.features.f_lat,
                        lng: context.state.features.f_lng,
                    },
                })
                .then((response) => {
                    context.commit('UPDATE_VIEW', {
                        gsv_available: response.status === 200,
                        gsv_ph: response.data.heading ? response.data.heading : null,
                        gsv_init_ph: response.data.heading ? response.data.heading : null,
                        gsv_pp: 0,
                        gsv_pz: 1,
                        gsv_p: response.data.pano_id ? response.data.pano_id : null,
                        gsv_lat: response.data.lat ? response.data.lat : null,
                        gsv_lng: response.data.lng ? response.data.lng : null,
                    })
                })
                .catch((error) => {
                    context.commit('UPDATE_VIEW', {
                        gsv_available: false,
                        gsv_ph: null,
                        gsv_init_ph: null,
                        gsv_pp: null,
                        gsv_pz: null,
                        gsv_p: null,
                        gsv_lat: null,
                        gsv_lng: null,
                    })
                })
        },
        check_authentication(context) {
            return axios.get('/auth/user').then((response) => {
                context.commit('SET_AUTH', response.data)
            })
        },
    },
    modules: {
        log,
    },
})

import Vue from 'vue'
import Router from 'vue-router'
import email from '@/shared/user'
import Home from '@/views/Home.vue'
import House from '@/views/House.vue'
import Report from '@/views/Report.vue'
import Login from '@/shared/components/Login.vue'
import Admin from '@/shared/components/Admin.vue'
import store from './store/index.js'
import i18n from '@/shared/i18n'

Vue.use(Router)

// get lang from route
const lang = localStorage.getItem('lang') || 'en-BE'

let loadQueryString = async (to) => {
    let query = Object.assign(
        {
            building_id: null,
            streetname: null,
            streetnumber: null,
            postalcode: null,
            municipality: null,
            gsv_pp: null,
            gsv_ph: null,
            gsv_pz: null,
            gsv_p: null,
        },
        to.query
    )
    if (query.postalcode) {
        store.commit('UPDATE_ADDRESS', {
            streetname: query.streetname,
            streetnumber: query.streetnumber,
            postalcode: query.postalcode,
            municipality: query.municipality,
        })
    }
    if (query.gsv_p) {
        store.commit('UPDATE_VIEW', {
            gsv_pp: query.gsv_pp,
            gsv_ph: query.gsv_ph,
            gsv_pz: query.gsv_pz,
            gsv_p: query.gsv_p,
        })
    }
    if (query.building_id !== undefined) {
        await store.dispatch('updateBuildingId', query.building_id)
    }
}

let beforeEnter = async (to, from, next) => {
    const langs = ['nl-BE', 'fr-BE', 'en-BE']
    // if correct lang update localStorage and update lang variable
    if (langs.includes(to.params.lang)) {
        localStorage.setItem('lang', to.params.lang)
        i18n.locale = to.params.lang
    }

    const actionList = ['3d', '2d', 'streetview']
    const action_exists = actionList.includes(to.params.action)
    const check_login = Vue.prototype.$config.CHECK_LOGIN
    if (check_login && store.state.auth.authenticated == null) {
        await store.dispatch('check_authentication')
    }

    let logged_in = store.state.auth.authenticated
    if (check_login && logged_in && to.name === 'login') {
        next({ name: 'start', params: { lang: lang } })
    } else if (to.name !== 'login' && check_login && !logged_in) {
        next({ name: 'login', params: { lang: lang } })
    } else if (to.name === 'login' && check_login && !logged_in) {
        next()
    } else if (
        (to.name === 'action' && action_exists) ||
        to.name === 'report' ||
        to.name === 'admin'
    ) {
        await loadQueryString(to)
        next()
    } else if (to.name === 'start') {
        console.log(store.getters)
        store.commit('UPDATE_MAP_SCENE2D', store.getters.getDefaultState.map_scene2d)
        next()
    } else {
        next({
            name: check_login && !logged_in ? 'login' : 'start',
            params: { lang: lang },
        })
    }
}

export function createRouter(conf) {
    return new Router({
        mode: 'history',
        base: process.env.VUE_APP_FRONTEND_URL,
        routes: [
            { name: 'root', path: '/', redirect: `/${lang}/start` },
            { path: '/:lang', redirect: '/:lang/start' },
            {
                path: '/default/:action',
                redirect: (to) => {
                    let action =
                        to.params.action === undefined ? 'start' : to.params.action
                    return { path: `/${lang}/${action}` }
                },
            },
            {
                path: '/:lang/start',
                name: 'start',
                component: Home,
                beforeEnter: beforeEnter,
            },
            {
                path: '/:lang/login',
                name: 'login',
                component: Login,
                beforeEnter: beforeEnter,
            },
            {
                path: '/:lang/report',
                name: 'report',
                component: Report,
                beforeEnter: beforeEnter,
            },
            {
                path: '/:lang/view/:action',
                name: 'action',
                component: House,
                beforeEnter: beforeEnter,
            },
            {
                path: '/:lang/admin',
                name: 'admin',
                component: Admin,
                beforeEnter: beforeEnter,
            },
            { path: '*', redirect: `/${lang}/start` },
        ],
    })
}
